import Helpers from '@/utilities/Helpers';
import i18n from '@/locales/_i18n';

export function required(value) {
  return !!value || i18n.t('requiredField');
}

export function validEmail(value) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || i18n.t('invalidEmail');
}

export function validPassword(value) {
  const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return pattern.test(value) || i18n.t('invalidPassword');
}

export function validCpf(value) {
  if (value) {
    const cpf = value.replace(/[^\d]+/g, '');
    return Helpers.methods.isCpf(cpf) || i18n.t('invalidCpf');
  }

  return i18n.t('invalidCpf');
}

export function validCnpj(value) {
  if (value) {
    const cnpj = value.replace(/[^\d]+/g, '');
    return Helpers.methods.isCnpj(cnpj) || i18n.t('invalidCnpj');
  }

  return i18n.t('invalidCnpj');
}